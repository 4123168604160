import React, { useState, useEffect } from "react"
import { Link } from "gatsby"
import { AnchorLink } from "gatsby-plugin-anchor-links"

import t from "../locale"

const Header = ({
  lang,
  translationPL,
  translationFO,
  translationEN,
  postTranslations,
  postLanguage,
}) => {
  const [isScrolled, setIsScrolled] = useState(false)
  const [isHomePage, setIsHomePage] = useState(false)
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  useEffect(() => {
    window.addEventListener("scroll", () => {
      setIsScrolled(window.scrollY > 50)
    })
    if (
      window.location.pathname === "/" ||
      window.location.pathname === "/pl/" ||
      window.location.pathname === "/en/"
    ) {
      setIsHomePage(true)
    }
  }, [])
  return (
    <header
      className={`header ${isScrolled ? "shrink" : ""} ${
        isHomePage === true ? "header--home" : ""
      }`}
    >
      <div className="container menu-container">
        <div className="header__logo-container">
          {lang === "en" ? (
            <>
              <Link to={t("/", lang)}>
                <img
                  src={require("../assets/images/logo-white-eng.svg")}
                  alt="Hogra"
                  className="logo--white"
                />
              </Link>
              <Link to={t("/", lang)}>
                <img
                  src={require("../assets/images/logo-black-eng.svg")}
                  alt="Hogra"
                  className="logo--black"
                />
              </Link>
            </>
          ) : lang === "pl" ? (
            <>
              <Link to={t("/", lang)}>
                <img
                  src={require("../assets/images/logo-white-pl.svg")}
                  alt="Hogra"
                  className="logo--white"
                />
              </Link>
              <Link to={t("/", lang)}>
                <img
                  src={require("../assets/images/logo-black-pl.svg")}
                  alt="Hogra"
                  className="logo--black"
                />
              </Link>
            </>
          ) : (
            <>
              <Link to={t("/", lang)}>
                <img
                  src={require("../assets/images/logo-white-fo.svg")}
                  alt="Hogra"
                  className="logo--white"
                />
              </Link>
              <Link to={t("/", lang)}>
                <img
                  src={require("../assets/images/logo-black-fo.svg")}
                  alt="Hogra"
                  className="logo--black"
                />
              </Link>
            </>
          )}
        </div>
        <nav
          className={`header__navigation navigation ${
            isMenuOpen === true ? "open" : ""
          }`}
        >
          <ul className="navigation__menu">
            <li>
              <Link
                to={t("/", lang)}
                className="menu-link"
                activeClassName="current"
              >
                {t("Home", lang)}
              </Link>
            </li>
            <li>
              <Link
                to={t("/about-us/", lang)}
                className="menu-link"
                activeClassName="current"
              >
                {t("About us", lang)}
              </Link>
            </li>
            <li onClick={e => setIsMenuOpen(!isMenuOpen)} role="presentation">
              <AnchorLink
                to={`${t("/", lang)}#portfolio`}
                className="menu-link"
                activeClassName="current"
              >
                {t("Portfolio", lang)}
              </AnchorLink>
            </li>
            <li onClick={e => setIsMenuOpen(!isMenuOpen)} role="presentation">
              <AnchorLink
                to={`${t("/", lang)}#campaigns`}
                className="menu-link"
                activeClassName="current"
              >
                {t("Campaigns", lang)}
              </AnchorLink>
            </li>
            <li>
              <Link
                to={t("/contact/", lang)}
                className="menu-link"
                activeClassName="current"
              >
                {t("Contact", lang)}
              </Link>
            </li>
            {/* <li>
              <div className="language-select">
                {postLanguage
                  ? postLanguage
                  : lang === "pl"
                  ? "Polski"
                  : lang === "en"
                  ? "English"
                  : lang === "fo"
                  ? "Føroyskt"
                  : ""}
                <div className="language-select__list">
                  {postTranslations ? (
                    <ul className="languages">
                      {postTranslations.map((node, index) => {
                        return (
                          <li key={index}>
                            <Link
                              to={`${
                                node.language.slug !== "fo"
                                  ? "/" + node.language.slug
                                  : ""
                              }/projects/${node.slug}/`}
                              key={index}
                            >
                              {node.language.name}
                            </Link>
                          </li>
                        )
                      })}
                    </ul>
                  ) : ( 
                    <ul className="languages">
                      {lang === "pl" ? (
                        <>
                          <li>
                            <Link to={translationEN}>English</Link>
                          </li>
                          <li>
                            <Link to={translationFO}>Føroyskt</Link>
                          </li>
                        </>
                      ) : lang === "en" ? (
                        <>
                          <li>
                            <Link to={translationPL}>Polski</Link>
                          </li>
                          <li>
                            <Link to={translationFO}>Føroyskt</Link>
                          </li>
                        </>
                      ) : lang === "fo" ? (
                        <>
                          <li>
                            <Link to={translationPL}>Polski</Link>
                          </li>
                          <li>
                            <Link to={translationEN}>English</Link>
                          </li>
                        </>
                      ) : (
                        ""
                      )}
                    </ul>
                  )}
                </div>
              </div>
            </li> */}
            <li>
              {lang === "pl" ? (
                <a className="phone-link" href="tel:+48698943949">
                  ZADZWOŃ +48 698 943 949
                </a>
              ) : lang === "fo" ? (
                <a className="phone-link" href="tel:+298223366">
                  RING +298 22 33 66
                </a>
              ) : (
                <div className="navigation__number-wrapper">
                  <a className="phone-link" href="tel:+298223366">
                    CALL US +298 22 33 66
                  </a>
                  <a className="phone-link" href="tel:+48698943949">
                    +48 698 943 949
                  </a>
                </div>
              )}
            </li>
          </ul>
        </nav>
        <button
          type="button"
          aria-label="Open menu"
          className={`hamburger hamburger--squeeze mobile-menu__hamburger ${
            isMenuOpen === true ? "is-active" : ""
          }`}
          onClick={e => setIsMenuOpen(!isMenuOpen)}
        >
          {Array(4)
            .fill(null)
            .map((item, index) => (
              <span key={index} />
            ))}
        </button>
      </div>
    </header>
  )
}

export default Header
